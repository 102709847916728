/// <amd-module name="legitdev.site.shell.min.InitializerMinShell" />
const minSrc = "src/legitdev.site.shell.shell.min.js";
const loader = new ShellLoader.Browser.Loader({
    logLevel: 5000,
    debug: false,
    loadingElementId: "legitdev-loading",
    storageIdentifier: "legitdev.site.v1",
    relativeBundle: "shell-data/bundles",
    source: [minSrc],
    paths: { pako: minSrc },
    appConfig: {
        id: "1",
        displayName: "Legit",
        repos: [
            {
                displayName: "Legit",
                id: "hosted-web-legitdev",
                order: 0,
                bundleId: "legitdev.site.shell.app.src.min.gitbundleinfo",
                defaultBranch: "refs/heads/main",
                defaultRefreshInterval: 60,
                loadConfigPath: ["loadConfig.json"],
                metadata: {
                    version: "1.0.0",
                    binRepoId: "hosted-web-legitdev-bin",
                    binHdRepoId: "hosted-web-legitdev-binhd",
                    binTutorialsRepoId: "hosted-web-site-tutorial-bin",
                    binGuideRepoId: "hosted-web-site-guide-bin"
                }
            },
            {
                displayName: "Legit Site Bin",
                id: "hosted-web-legitdev-bin",
                bundleId: "legitdev.site.shell.app.bin.min.gitbundleinfo",
                defaultBranch: "refs/heads/main",
                defaultRefreshInterval: 60,
                order: 0
            },
            {
                displayName: "Legit Site Bin HD",
                id: "hosted-web-legitdev-binhd",
                bundleId: "legitdev.site.shell.app.binhd.min.gitbundleinfo",
                defaultBranch: "refs/heads/main",
                defaultRefreshInterval: 60,
                order: 1,
                autoLoadWithRuntime: false
            },
            {
                displayName: "Legit Site Tutorial Bin",
                id: "hosted-web-site-tutorial-bin",
                bundleId: "legitdev.site.shell.app.tutorial.min.gitbundleinfo",
                defaultBranch: "refs/heads/main",
                defaultRefreshInterval: 60,
                order: 1,
                autoLoadWithRuntime: false
            },
            {
                displayName: "Legit Site Bin Guide",
                id: "hosted-web-site-guide-bin",
                bundleId: "legitdev.site.shell.app.guide.min.gitbundleinfo",
                defaultBranch: "refs/heads/main",
                defaultRefreshInterval: 60,
                order: 1,
                autoLoadWithRuntime: false
            }
        ]
    }
});

loader.load();
